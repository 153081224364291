import Vue from "vue";
import VueRouter from "vue-router";
import { isMobile } from "./utils";
Vue.use(VueRouter);
//pc端的路由
export const pcRoutes = [
    {
        path: "/",
        name: "Home",
        component: () => import("@/views/pcHome"),
    },
    {
        path: "/buahKita",
        name: "buahKita",
        component: () => import("@/views/pcBuahKita"),
    },
    { path: "*", redirect: "/" },
];
//移动端设备路由
export const mobileRoutes = [
    {
        path: "/",
        name: "Home",
        component: () => import("@/views/mobileHome"),
    },
    {
        path: "/buahKita",
        name: "buahKita",
        component: () => import("@/views/mobileBuahKita"),
    },
    { path: "*", redirect: "/" },
];
const createRouter = () =>
    new VueRouter({
        scrollBehavior: () => ({ y: 0 }),
        mode: "hash",
        routes: isMobile() ? mobileRoutes : pcRoutes,
    });

const router = createRouter();
export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher;
}
export default router;

<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
import { debounce } from "@/utils.js";
export default {
    name: "App",
    data() {
        return {
            innerWidth: 0,
        };
    },
    methods: {
        handleResize: debounce(
            function () {
                if ((this.innerWidth >= 1040 && window.innerWidth < 1040) || (this.innerWidth < 1040 && window.innerWidth >= 1040)) {
                    window.location.reload();
                }
            },
            1000,
            false
        ),
    },
    mounted() {
        this.innerWidth = window.innerWidth;
        window.addEventListener("resize", this.handleResize);
    },
};
</script>

<style>
* {
    margin: 0;
    padding: 0;
}
html,
body {
    width: 100%;
    height: 100%;
}
#app {
    font-family: Poppins, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    /* min-width: 1040px; */
    margin: 0 auto;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'postcss-px-to-viewport'
// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'
import "@/assets/css/reset.css"
import router from './router'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
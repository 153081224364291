export const isMobile = () => {
    // return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
    //     navigator.userAgent
    // );
    return document.body.clientWidth <= 1040;
};
export function debounce(func, wait, immediate) {
    let timeout = null;
    return function (...args) {
        let context = this;
        if (timeout) clearTimeout(timeout);
        if (immediate) {
            let callNow = !timeout;
            timeout = setTimeout(function () {
                timeout = null;
            }, wait);
            if (callNow) func.apply(context, args);
        } else {
            timeout = setTimeout(function () {
                func.apply(context, args);
            }, wait)
        }
    }
}
export const links = [
    {
        name: "Travel",
        path: "https://www.webuysg.com/travel",
    },
    {
        name: "Buah Kita",
        path: "/buahKita",
    },
    {
        name: "Download",
        path: "/#download",
    },
    {
        name: "About",
        path: "/#about",
    },
    {
        name: "Customers",
        path: "/#customers",
    },
    {
        name: "Partners",
        path: "/#partners",
    },
    {
        name: "Merchants",
        path: "/#merchants",
    },
    {
        name: "Newsroom",
        path: "/#newsroom",
    },
    {
        name: "Investors Relation",
        path: "https://www.webuysg.com/Investor/",
    },
    {
        name: "Contact",
        path: "/#contact",
    },
]